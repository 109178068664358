.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
  border-radius: 4px;
}

.border-device-table {
  border: 1px #a0a0a06e solid;
  padding: 0.5px;
  border-radius: 5px;
}

.seperator-80 {
  height: 30px;
}

.header-device {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  width: 85%;
  margin: 0px auto;
}

.margin-device-screen {
  display: flex;
  width: 85%;
  margin: 0px auto;
}

.form-search-gsfid {
  display: flex;
  width: auto;
  align-items: center;
}

.form-search-gsfid > input {
  min-width: 250px;
  margin-right: 20px;
}

.form-search-gsfid > button {
  width: auto;
  height: fit-content;
  margin-left: 20px;
  margin-top: 0 !important;
}


.imageUserDeviceScreen > img {
  border-radius: 25px;
  border: 0.5px #a0a0a06e solid;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.form-user-logout {
  display: flex;
  align-items: center;
}

.form-user-logout > h6 {
  font-size: 14px;
  margin-bottom: 0px;
}

.form-user-logout > .device-username {
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}


.form-user-logout > .device-logout {
  margin-left: 10px;
  cursor: pointer;
}

.form-user-logout > .device-logout:hover {
  opacity: 0.7;
}

.form-user-logout > .device-logout:active {
  color: #00B678;
}

.device-vertical-separator {
  border-left: 2px solid #2125298f;
  height: 18px;
}

.table-height {
  height: calc(100vh - 100px);
}

.version-name {
  font-size: 13px;
  color: #777777a1;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
