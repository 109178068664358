@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
.App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  background: #00B678 !important;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #00B678;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #00B678;
}

.imageLogin {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
}

.imageLogin > img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #00B678 !important;
  border-color: #00B678 !important;
}

.btn-primary:hover {
  background-color: #00b679d5 !important;
  border-color: #00B678 !important;
}

.version-name-login {
  font-size: 13px;
  color: #777777a1;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
  border-radius: 4px;
}

.border-device-table {
  border: 1px #a0a0a06e solid;
  padding: 0.5px;
  border-radius: 5px;
}

.seperator-80 {
  height: 30px;
}

.header-device {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  width: 85%;
  margin: 0px auto;
}

.margin-device-screen {
  display: flex;
  width: 85%;
  margin: 0px auto;
}

.form-search-gsfid {
  display: flex;
  width: auto;
  align-items: center;
}

.form-search-gsfid > input {
  min-width: 250px;
  margin-right: 20px;
}

.form-search-gsfid > button {
  width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 20px;
  margin-top: 0 !important;
}


.imageUserDeviceScreen > img {
  border-radius: 25px;
  border: 0.5px #a0a0a06e solid;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.form-user-logout {
  display: flex;
  align-items: center;
}

.form-user-logout > h6 {
  font-size: 14px;
  margin-bottom: 0px;
}

.form-user-logout > .device-username {
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}


.form-user-logout > .device-logout {
  margin-left: 10px;
  cursor: pointer;
}

.form-user-logout > .device-logout:hover {
  opacity: 0.7;
}

.form-user-logout > .device-logout:active {
  color: #00B678;
}

.device-vertical-separator {
  border-left: 2px solid #2125298f;
  height: 18px;
}

.table-height {
  height: calc(100vh - 100px);
}

.version-name {
  font-size: 13px;
  color: #777777a1;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

