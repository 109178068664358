@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

.App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  background: #00B678 !important;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #00B678;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #00B678;
}

.imageLogin {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
}

.imageLogin > img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #00B678 !important;
  border-color: #00B678 !important;
}

.btn-primary:hover {
  background-color: #00b679d5 !important;
  border-color: #00B678 !important;
}

.version-name-login {
  font-size: 13px;
  color: #777777a1;
}